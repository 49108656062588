import LocalizedLink from "../../../hoc/LocalizedLink"
import IconBtn from "../../Assets/IconBtn/IconBtn"
import ArticlesList from "../ArticlesList/ArticlesList"
import { useAppSelector } from "../../../hooks"
import { selectUser } from "../../../redux/slice/auth"
import { useGetArticlesQuery, useLazyGetArticleQuery } from "../../../redux/api/articles"
import clsx from "clsx"
import useWindowSize from "../../../hooks/useWindowSize"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import styles from "./ArticlesAside.module.scss"
import { IArticle } from "../../../types/content"

const ArticlesAside = ({ onlyChild }: { onlyChild?: boolean }) => {
  const { id } = useParams()
  const { t } = useTranslation("translation", { keyPrefix: `interface` })
  const user = useAppSelector(selectUser)
  const { isDesktop } = useWindowSize()

  const { data: articles, isLoading } = useGetArticlesQuery(undefined, { skip: !user?.id })
  const [getArticle, { isLoading: isLoadingArticle, isFetching, isUninitialized }] = useLazyGetArticleQuery()
  const [articlesTree, setArticlesTree] = useState<IArticle[]>([])

  useEffect(() => {
    if (!id || isUninitialized) return
    getArticle(id)
  }, [id, user, isUninitialized])

  const findChildArticles = (articles: IArticle[], parentId: string | 0 = 0): IArticle[] => {
    let children: any[] = []

    articles.forEach((record) => {
      if (onlyChild) {
        if (record.parent_id === parentId) {
          children.push(record)

          // Рекурсивно ищем дочерние записи для текущей записи
          const nestedArticles = findChildArticles(articles, record.id)
          children = children.concat(nestedArticles)
        }
      } else {
        children.push(record)
      }
    })

    return children
  }

  useEffect(() => {
    if (articles) {
      setArticlesTree(findChildArticles(articles, id))
    }
  }, [articles, onlyChild, id])

  return user?.id ? (
    <>
      {!onlyChild && isDesktop && (
        <LocalizedLink to={"/"} className={styles.btn}>
          <IconBtn icon={"back"} borderSize={"circle"} type={"span"} className={styles.iconBack} />
          <span>
            {t("mainMenu")}
            <span className={styles.btnTxt}>{t("goBack")}</span>
          </span>
        </LocalizedLink>
      )}

      {isLoading ? (
        <div className={clsx("skeletonBlock", styles.loader)} />
      ) : (
        <>{articlesTree.length ? <ArticlesList articles={articlesTree} onlyChild={onlyChild} /> : null}</>
      )}
    </>
  ) : isDesktop && !isLoadingArticle && !isFetching && !isUninitialized ? (
    <div className={styles.btn}>
      <IconBtn icon={"book"} borderSize={"circle"} type={"span"} className={styles.iconBack} />
      <span>
        {t("knowledgeBase")}
        <span className={styles.btnTxt}>{t("usefulArticles")}</span>
      </span>
    </div>
  ) : null
}

export default ArticlesAside
