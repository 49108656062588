import clsx from "clsx"
import styles from "./Equipments.module.scss"
import { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from "react"
import TitleBack from "../TitleBack/TitleBack"
import { useTranslation } from "react-i18next"
import Equipment from "./Equipment/Equipment"
import useWindowSize from "../../hooks/useWindowSize"
import { useLazyGetIssuedEquipmentQuery } from "../../redux/api/content"
import { IEquipment } from "../../types/content"
import ModalEquipment from "./Equipment/ModalEquipment"

interface Props {
  className?: string
}

const RETURNED_LIST_LIMIT = 10

const Equipments = ({ className }: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: `interface` })
  const [getEquipment, { isFetching, isLoading, isUninitialized }] = useLazyGetIssuedEquipmentQuery()
  const { isMobile } = useWindowSize()
  const loaderRef = useRef(null)
  const listContainerRef = useRef(null)
  const [openedEquipment, setOpenedEquipment] = useState<undefined | IEquipment>()

  const [planedList, setPlanedList] = useState<IEquipment[]>([])
  const [providedList, setProvidedList] = useState<IEquipment[]>([])
  const [returnedList, setReturnedList] = useState<IEquipment[]>([])

  const [returnedOffset, setReturnedOffset] = useState<number>(0)
  const [returnedListIsEnd, setReturnedListIsEnd] = useState<boolean>(false)
  const [returnedIsLoading, setReturnedIsLoading] = useState(false)

  // isLoader для всех видов
  const [planedListLoader, setPlanedListLoader] = useState<boolean>(true)
  const [providedListLoader, setProvidedListLoader] = useState<boolean>(true)
  const [returnedListLoader, setReturnedListLoader] = useState<boolean>(true)

  useEffect(() => {
    getEquipment({ status: "planed" })
      .unwrap()
      .then((res) => {
        setPlanedList(res.data.aItems)
        setPlanedListLoader(false)
      })
    getEquipment({ status: "provided" })
      .unwrap()
      .then((res) => {
        setProvidedList(res.data.aItems)
        setProvidedListLoader(false)
      })
    fetchReturnedList()
  }, [])

  useEffect(() => {
    if (isUninitialized) return

    const observer = new IntersectionObserver((entries) => {
      const target = entries[0]
      if (target.isIntersecting) {
        if (isFetching || isLoading || returnedIsLoading) return

        fetchReturnedList()
      }
    })

    if (loaderRef.current) {
      observer.observe(loaderRef.current)
    }

    return () => {
      if (loaderRef.current) {
        observer.unobserve(loaderRef.current)
        observer.disconnect()
      }
    }
  }, [isUninitialized, returnedList, isFetching, isLoading, returnedIsLoading])

  const fetchReturnedList = () => {
    if (returnedListIsEnd || isFetching || isLoading) return

    setReturnedIsLoading(true)
    getEquipment({ status: "returned", limit: RETURNED_LIST_LIMIT, offset: returnedOffset * RETURNED_LIST_LIMIT })
      .unwrap()
      .then((res) => {
        setReturnedOffset((prev) => prev + 1)
        setReturnedList((prev) => {
          return [...prev, ...res.data.aItems]
        })
        setReturnedListLoader(false)
        setReturnedIsLoading(false)
        setReturnedListIsEnd(res.data.bIsEnd)
      })
  }

  const equipmentList = useCallback((arr: any[]): ReactElement => {
    return (
      <div className={styles["list__container"]}>
        {arr.map((el, i) => {
          return <Equipment key={i} equipment={el} setOpenedEquipment={setOpenedEquipment} />
        })}
      </div>
    )
  }, [])

  const Loader = () => (
    <div className={styles["list__container"]}>
      {[...Array(3)].map((_item, index) => (
        <div key={index} className={clsx("skeletonBlock", styles["equipment-loader"])} />
      ))}
    </div>
  )

  const isViewNotYetItems = useMemo((): boolean => {
    return (
      !planedListLoader &&
      !providedListLoader &&
      !returnedListLoader &&
      !planedList.length &&
      !providedList.length &&
      !returnedList.length
    )
  }, [planedListLoader, providedListLoader, returnedListLoader, planedList, providedList, returnedList])

  return (
    <div className={clsx(className, styles["equipments"], { [styles["equipments__mobile"]]: isMobile })}>
      <TitleBack noLinkBack title={t("equipment")} />
      {isViewNotYetItems && <div className={styles.noEquipment}>{t("noEquipmentYet")}</div>}
      <div className={styles["equipments__lists-container"]}>
        {planedListLoader ? (
          <Loader />
        ) : (
          <>
            {planedList.length > 0 && (
              <div className={styles.list}>
                <h4 className={styles["list__title"]}>{t("awaitingDelivery")}</h4>
                {equipmentList(planedList)}
              </div>
            )}
          </>
        )}

        {providedListLoader ? (
          <Loader />
        ) : (
          <>
            {providedList.length > 0 && (
              <div className={styles.list}>
                <h4 className={styles["list__title"]}>{t("delivered")}</h4>
                {equipmentList(providedList)}
              </div>
            )}
          </>
        )}

        {returnedListLoader ? (
          <Loader />
        ) : (
          <>
            {returnedList.length > 0 && (
              <div ref={listContainerRef} className={styles.list}>
                <h4 className={styles["list__title"]}>{t("archive")}</h4>
                {equipmentList(returnedList)}
                {!returnedListIsEnd && (
                  <div ref={loaderRef} style={{ marginTop: "12px" }}>
                    <Loader />
                  </div>
                )}
              </div>
            )}
          </>
        )}
      </div>
      {openedEquipment && (
        <ModalEquipment open={!!openedEquipment} setOpen={setOpenedEquipment} equipment={openedEquipment} />
      )}
    </div>
  )
}

export default Equipments
