import { useEffect, useMemo, useState } from "react"
import ArticleListAccordion from "../ArticleListAccordion/ArticleListAccordion"
import ArticleItemAccordion from "../ArticleItemAccordion/ArticleItemAccordion"
import { TreeUpIcon } from "../../../icons/TreeUpIcon"
import { TreeDownIcon } from "../../../icons/TreeDownIcon"
import LocalizedLink from "../../../hoc/LocalizedLink"
import ScrollBlock from "../../Assets/ScrollBlock"
import { IArticle, IExArticle } from "../../../types/content"
import { useAppSelector } from "../../../hooks"
import { selectArticles } from "../../../redux/slice/articles"
import useWindowSize from "../../../hooks/useWindowSize"
import Accordion from "../../Assets/Accordion/Accordion"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import styles from "./ArticlesList.module.scss"
import clsx from "clsx"

const deepFindIsOpen = (parentArr: IExArticle[]): IExArticle | null => {
  const needEl = parentArr.find((el) => el.isOpen)
  let isFind = false
  if (needEl) return needEl

  let result: IExArticle | null = null
  parentArr.forEach((child) => {
    if (child?.children) {
      child?.children?.forEach((childItem: IExArticle) => {
        if (!isFind && childItem.children) {
          result = deepFindIsOpen(childItem.children)
          if (result) isFind = true
        }
      })
    }
  })
  return result
}

const toggleArticlesListInAsideLocal = (aside: IExArticle[], onlyChild?: boolean) => {
  if (!aside) return null
  const isOpenExist = deepFindIsOpen(aside)?.isOpen
  const newAside = aside.map((article) => {
    // 1й элемент
    article.isOpen = !isOpenExist
    // 1я вложенность
    if (article.children.length > 0) {
      article.children.map((articleChild) => {
        articleChild.isOpen = !isOpenExist
        // 2я вложенность
        if (articleChild.children.length > 0) {
          articleChild.children.map((childItem) => {
            childItem.isOpen = !isOpenExist
          })
        }
      })
    }
    return article
  })
  localStorage.setItem(onlyChild ? "articleInItemList" : "articleList", JSON.stringify(newAside))

  return newAside
}

const ArticlesList = ({ articles, onlyChild }: { articles: IArticle[]; onlyChild?: boolean }) => {
  const { t } = useTranslation("translation", { keyPrefix: `interface` })
  const params = useParams()
  const { favoriteArticles } = useAppSelector(selectArticles)
  const { isDesktop } = useWindowSize()

  const [maxHeight, setMaxHeight] = useState<number | undefined>(undefined)
  const [isGlobalOpen, setGlobalOpen] = useState<boolean>(false)
  const [aside, setAside] = useState<IExArticle[] | null>(null)

  useEffect(() => {
    // устанавливаем данные с localStorage в хук aside при заходе на страницу
    const articlesAsideListLocal = localStorage.getItem(onlyChild ? "articleInItemList" : "articleList")
    const articlesAsideList: IExArticle[] | null = articlesAsideListLocal ? JSON.parse(articlesAsideListLocal) : null
    if (articlesAsideList) setAside(articlesAsideList)
  }, [onlyChild])

  useEffect(() => {
    // если в localeStorage нет aside-списка, то устанавливаем его
    const articlesAsideIDsLocal = localStorage.getItem("articlesIDs")
    const articlesAsideIDs: string[] = articlesAsideIDsLocal ? JSON.parse(articlesAsideIDsLocal) : null

    if (!onlyChild) {
      // если есть IDs в localStorage, и длины массивов совпадают, и нет новой article, то делаем выход
      if (articlesAsideIDs) {
        // если длины массиво совпадают, то проверям, нет ли новой статьи(ищем по её id)
        if (articlesAsideIDs?.length === articles?.length) {
          const newArticle = articles.filter((article) => !articlesAsideIDs.includes(article.id))
          if (!newArticle?.length) return
        }
      }
    }

    const buildTreeAndOpen = (elements: IArticle[]) => {
      const idMap: any = {}
      const tree = []

      // Создаем словарь для быстрого доступа к элементам по id
      elements.forEach((item: IArticle) => {
        idMap[item.id] = { ...item, children: [] }
      })

      // Строим древовидную структуру
      for (const item of elements) {
        if (item.parent_id === 0 || (onlyChild && item.parent_id === params.id)) {
          // Если это корневой элемент, добавляем его в дерево
          tree.push(idMap[item.id])
        } else {
          // Если это дочерний элемент, добавляем его к родителю
          const parent = idMap[item.parent_id]
          if (parent) {
            parent.children.push(idMap[item.id])
          }
        }
      }

      // Функция для открытия элемента и всех его предков
      const openAncestors = (id: string) => {
        let current = idMap[id]
        while (current) {
          current.isOpen = true
          current = idMap[current.parent_id]
        }
      }

      // Находим элемент с params.id и открываем его предков
      if (params.id) openAncestors(params.id)

      return tree
    }

    const outputArticles = buildTreeAndOpen(articles)

    if (!onlyChild) {
      const uniqueIds = [...new Set(articles?.map((article) => article.id))]

      localStorage.setItem("articleList", JSON.stringify(outputArticles))
      localStorage.setItem("articlesIDs", JSON.stringify(uniqueIds))
    } else {
      localStorage.setItem("articleInItemList", JSON.stringify(outputArticles))
    }

    setAside(outputArticles)
  }, [articles, params.id, onlyChild])

  const articlesList = useMemo(() => {
    if (!aside) return
    const recursiveInsertArticleContent = (article: any) => {
      if (article?.children?.length > 0) {
        return (
          <ArticleListAccordion
            key={article.id}
            name={article.text}
            href={article.id}
            isArticleOpen={article.isOpen}
            setAside={setAside}
            onlyChild={onlyChild}
          >
            {article.children.map((i: any) => {
              return recursiveInsertArticleContent(i)
            })}
          </ArticleListAccordion>
        )
      } else {
        return (
          <ArticleItemAccordion
            key={article.id}
            txt={article.text}
            href={article.id}
            isFavorite={Boolean(favoriteArticles?.find((i) => i.id === article.id))}
          />
        )
      }
    }

    return (
      <div className={styles.inner}>
        {aside?.map((article, index) => {
          return (
            <ArticleListAccordion
              key={index}
              name={article.text}
              href={article.id}
              isArticleOpen={article.isOpen}
              isChildren={article?.children?.length > 0}
              setAside={setAside}
              onlyChild={onlyChild}
            >
              {article?.children?.map((childArticle: any) => recursiveInsertArticleContent(childArticle))}
            </ArticleListAccordion>
          )
        })}
      </div>
    )
  }, [aside, favoriteArticles, params, onlyChild])

  const globalTrigger = () => {
    setGlobalOpen((prev) => !prev)
    const newAside = aside ? toggleArticlesListInAsideLocal(aside, onlyChild) : null
    if (newAside) setAside(newAside)
  }

  useEffect(() => {
    function setMax() {
      const windowHeight = window.innerHeight
      const headerHeight = (document.querySelector(".header") as HTMLDivElement)?.offsetHeight
      // высота экрана - высота хедера - отступы у aside сверху/снизу - высота кнопки Назад(на Главную) - хедер aside
      setMaxHeight(windowHeight - headerHeight - 50 - 72 - 44)
    }
    setMax()

    window.addEventListener("resize", setMax)
    return () => window.removeEventListener("resize", setMax)
  }, [])

  useEffect(() => {
    if (!aside) return
    const isOpenExist = deepFindIsOpen(aside)?.isOpen
    setGlobalOpen(isOpenExist || false)
  }, [aside])

  return (
    <div className={clsx(styles.aside, params.id && styles.marginBottom)}>
      {isDesktop ? (
        <>
          {!onlyChild && (
            <header className={styles.header}>
              <LocalizedLink to={"/articles"} className={styles.link}>
                {t(isDesktop ? "toMain" : "allArticles")}
              </LocalizedLink>
              <button onClick={globalTrigger} className={styles.btn}>
                {isGlobalOpen ? <TreeUpIcon /> : <TreeDownIcon />}
              </button>
            </header>
          )}

          <ScrollBlock hideTracksWhenNotNeeded autoHeightMax={maxHeight}>
            {articlesList}
          </ScrollBlock>
        </>
      ) : (
        <Accordion header={t("allArticles")} className={styles["order-descr-accord"]} defaultOpen={false}>
          <ScrollBlock hideTracksWhenNotNeeded autoHeightMax={maxHeight}>
            {articlesList}
          </ScrollBlock>
        </Accordion>
      )}
    </div>
  )
}

export default ArticlesList
